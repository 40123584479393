/* ------------------------------------*\
 * JS Main entry file
\* ------------------------------------*/
  // eslint-disable-next-line import/order
import { loadChunks, loadServiceWorker } from 'app_designholding/internal/initApp';
// Custom babel polyfill import to prevent multiple requires of the polyfill
import '@utilities/require-babel-polyfill.js';
// Import Stylesheet.
import '../scss/style.scss';
// Import Polyfills.
import '@polyfills';
// Import utilities.
import moduleInit from '@utilities/module-init';
// Import Singletons
import Events from '@utilities/events';
import '@utilities/drag';
import '@utilities/google-maps';
import '@utilities/gtm';
import '@utilities/detect-touch';
import '@utilities/detect-keyboard-focus';
import '@utilities/focus-trap';
import '@utilities/init-js';
import '@utilities/in-view';
import '@utilities/scroll-to';
import '@utilities/history';
import '@utilities/page-css-variables';

// Import modules
import '@components/cookies';
import '@components/image';
import '@components/loader';
import VideoLoader from '@components/video/loader';

// Init service worker
loadServiceWorker();

// Async
moduleInit.async('html', () =>
  import(/* webpackChunkName: "js/legacy/PlatformDetect" */ '@components/platform-detection'),
);

moduleInit.async('[js-hook-enhanced-country-selector]', () =>
  import(/* webpackChunkName: "js/legacy/EnhancedCountrySelector" */ '@components/enhanced-country-selector'),
);

moduleInit.async('[js-hook-giftoption-form]', () =>
  import(/* webpackChunkName: "js/legacy/GiftOptionForm" */ '@components/giftoption-form'),
);

moduleInit.async('[js-hook-generate-pdf]', () =>
  import(/* webpackChunkName: "js/legacy/GeneratePDF" */ '@components/generate-pdf'),
);

moduleInit.async('[js-hook-skip-link]', () => import(/* webpackChunkName: "js/legacy/SkipLink" */ '@utilities/skip-link'));
moduleInit.async('[js-hook-paged-navigation]', () => import(/* webpackChunkName: "js/legacy/PagedNavigation" */ '@components/paged-navigation'));

moduleInit.async('[js-hook-basic-slider]', () =>
  import(/* webpackChunkName: "js/legacy/BasicSlider" */ '@components/basic-slider'),
);

moduleInit.async('[js-hook-fade-slider]', () =>
  import(/* webpackChunkName: "js/legacy/FadeSlider" */ '@components/fade-slider'),
);

moduleInit.async('[js-hook-store-inventory-form]', () =>
  import(/* webpackChunkName: "js/legacy/StoreInventoryForm" */ '@components/store-inventory-form/store-inventory-form'),
);
moduleInit.async('[js-hook-store-inventory-button]', () =>
  import(/* webpackChunkName: "js/legacy/StoreInventoryButton" */ '@components/store-inventory-button/store-inventory-button'),
);
moduleInit.async('[js-hook-store-locator]', () =>
  import(/* webpackChunkName: "js/legacy/StoreLocator" */ '@components/store-locator'),
);
moduleInit.async('[js-hook-store-locator-map]', () =>
  import(/* webpackChunkName: "js/legacy/StoreLocatorMap" */ '@components/store-locator/store-locator-map'),
);
moduleInit.async('[js-hook-store-locator-results]', () =>
  import(/* webpackChunkName: "js/legacy/StoreLocatorResults" */ '@components/store-locator/store-locator-results'),
);
moduleInit.async('[js-hook-store-locator-online-retailers]', () =>
  import(/* webpackChunkName: "js/legacy/OnlineRetailers" */ '@components/store-locator/store-locator-online-retailers'),
);
moduleInit.async('[js-hook-store-locator-form]', () =>
  import(/* webpackChunkName: "js/legacy/StoreLocatorForm" */ '@components/store-locator/store-locator-form'),
);
moduleInit.async('[js-hook-store-locator-filter]', () =>
  import(/* webpackChunkName: "js/legacy/RefinementFilter" */ '@components/store-locator-filter'),
);
moduleInit.async('[js-hook-toggle]', () =>
  import(/* webpackChunkName: "js/legacy/Toggle" */ '@components/toggle'),
);
moduleInit.async('[js-hook-refinements]', () =>
  import(/* webpackChunkName: "js/legacy/Refinements" */ '@utilities/refinements'),
);
moduleInit.async('[js-hook-gtm]', () =>
  import(/* webpackChunkName: "js/legacy/GTMEvents" */ '@components/gtm-events'),
);
moduleInit.async('[js-hook-header]', () =>
  import(/* webpackChunkName: "js/legacy/Header" */ '@components/header'),
);
moduleInit.async('[js-hook-page]', () =>
  import(/* webpackChunkName: "js/legacy/Page" */ '@components/page'),
);
moduleInit.async('[js-hook-ajax-modal-button]', () =>
  import(/* webpackChunkName: "js/legacy/AjaxModalButton" */ '@components/ajax-modal-button'),
);
moduleInit.async('[js-hook-alert]', () =>
  import(/* webpackChunkName: "js/legacy/Alert" */ '@components/alert'),
);
moduleInit.async('[js-hook-accordion]', () =>
  import(/* webpackChunkName: "js/legacy/Accordion" */ '@components/accordion'),
);
moduleInit.async('[js-hook-add-to-cart]', () =>
  import(/* webpackChunkName: "js/legacy/AddToCart" */ './components/add-to-cart'),
);
moduleInit.async('[js-hook-go-to-stores]', () =>
  import(/* webpackChunkName: "js/legacy/GoToStore" */ '@components/go-to-stores'),
);
moduleInit.async('[js-hook-back-to-top]', () =>
  import(/* webpackChunkName: "js/legacy/BackToTop" */ '@components/back-to-top'),
);
moduleInit.async('[js-hook-background-light]', () =>
  import(/* webpackChunkName: "js/legacy/BackgroundgroundLight" */ '@components/background-light'),
);
moduleInit.async('[js-hook-contact-form]', () =>
  import(/* webpackChunkName: "js/legacy/ContactForm" */ '@components/contact-form'),
);
moduleInit.async('[js-hook-book-an-appointment-form]', () =>
  import(/* webpackChunkName: "js/legacy/BookAnAppointmentForm" */ '@components/book-an-appointment-form'),
);
moduleInit.async('[js-hook-contact-form-email]', () =>
  import(/* webpackChunkName: "js/legacy/ContactFormEmail" */ '@components/contact-form-email'),
);
moduleInit.async('[js-hook-chat]', () =>
  import(/* webpackChunkName: "js/legacy/Chat" */ '@components/chat'),
);
moduleInit.async('[js-hook-coupon-form]', () =>
  import(/* webpackChunkName: "js/legacy/CouponForm" */ '@components/coupon-form'),
);
moduleInit.async('[js-hook-fancy-list]', () =>
  import(/* webpackChunkName: "js/legacy/FancyList" */ '@components/fancy-list'),
);
moduleInit.async('[js-hook-lap]', () =>
  import(/* webpackChunkName: "js/legacy/LabelAsPlaceholder" */ '@components/form-elements/label-as-placeholder'),
);
moduleInit.async('[js-hook-mega-menu]', () =>
  import(/* webpackChunkName: "js/legacy/MegaMenu" */ '@components/mega-menu'),
);
moduleInit.async('[js-hook-input-sync]', () =>
  import(/* webpackChunkName: "js/legacy/InputSync" */ '@components/input-sync'),
);
moduleInit.async('[js-hook-mobile-navigation]', () =>
  import(/* webpackChunkName: "js/legacy/MobileNavigation" */ '@components/navigation'),
);
moduleInit.async('[js-hook-modal]', () =>
  import(/* webpackChunkName: "js/legacy/Modal" */ '@components/modal'),
);
moduleInit.async('[js-hook-ajax-modal]', () =>
  import(/* webpackChunkName: "js/legacy/AjaxModal" */ '@components/modal/ajax-modal'),
);
moduleInit.async('[js-hook-minicart-update]', () =>
  import(/* webpackChunkName: "js/legacy/Minicart" */ '@components/minicart'),
);
moduleInit.async('[js-hook-newsletter-form]', () =>
  import(/* webpackChunkName: "js/legacy/NewsletterForm" */ '@components/newsletter-form'),
);
moduleInit.async('[js-hook-product-attribute-size]', () =>
  import(/* webpackChunkName: "js/legacy/ProductAttributeSize" */ '@components/product-attribute/size'),
);
moduleInit.async('[js-hook-pagination]', () =>
  import(/* webpackChunkName: "js/legacy/Pagination" */ '@components/pagination'),
);
moduleInit.async('[js-hook-product-detail]', () =>
  import(/* webpackChunkName: "js/legacy/ProductDetail" */ '@components/product-detail'),
);
moduleInit.async('[js-hook-product-detail-hero]', () =>
  import(/* webpackChunkName: "js/legacy/ProductDetailHero" */ '@components/product-detail-hero'),
);
moduleInit.async('[js-hook-tab-nav-item]', () =>
  import(/* webpackChunkName: "js/legacy/TabNav" */ '@components/tab-nav'),
);
moduleInit.async('[js-hook-toggle-password]', () =>
  import(/* webpackChunkName: "js/legacy/TogglePassword" */ '@components/toggle-password'),
);
moduleInit.async('[js-hook-tooltip]', () =>
  import(/* webpackChunkName: "js/legacy/Tooltip" */ '@components/tooltip'),
);
moduleInit.async('[js-hook-search]', () =>
  import(/* webpackChunkName: "js/legacy/Search" */ '@components/search'),
);
moduleInit.async('[js-hook-set-cookie-on-click]', () =>
  import(/* webpackChunkName: "js/legacy/SetCookieOnClick" */ '@components/set-cookie-on-click'),
);
moduleInit.async('[js-hook-professional-trigger]', () =>
  import(/* webpackChunkName: "js/legacy/ProfessionalTrigger" */ '@components/professional-trigger'),
);
moduleInit.async('[js-hook-sort-select-box]', () =>
  import(/* webpackChunkName: "js/legacy/SortSelectBox" */ '@components/sort-select-box'),
);
moduleInit.async('[js-hook-scroll-gradient]', () =>
  import(/* webpackChunkName: "js/legacy/ScrollGradient" */ '@components/scroll-gradient'),
);
moduleInit.async('[js-hook-icon-list-carousel]', () =>
  import(/* webpackChunkName: "js/legacy/IconListCarousel" */ '@components/icon-list-carousel'),
);
moduleInit.async('[js-hook-navigation-bar]', () =>
  import(/* webpackChunkName: "js/legacy/NavigationBar" */ '@components/navigation-bar'),
);
moduleInit.async('[js-hook-scroll-into-view]', () =>
  import(/* webpackChunkName: "js/legacy/ScrollIntoView" */ '@components/scroll-into-view'),
);
moduleInit.async('[js-hook-remove-product]', () =>
  import(/* webpackChunkName: "js/legacy/RemoveProduct" */ '@components/remove-product'),
);
moduleInit.async('[js-hook-show-refinement-filters]', () =>
  import(/* webpackChunkName: "js/legacy/ShowRefinementFilters" */ '@components/show-refinement-filters'),
);
moduleInit.async('[js-hook-dropdown]', () =>
  import(/* webpackChunkName: "js/legacy/Dropdown" */ '@components/dropdown'),
);
moduleInit.async('[js-hook-refinement-filter]', () =>
  import(/* webpackChunkName: "js/legacy/RefinementFilter" */ '@components/refinement-filter'),
);
moduleInit.async('[js-hook-product-attribute]', () =>
  import(/* webpackChunkName: "js/legacy/ProductAttribute" */ './components/product-attribute'),
);
moduleInit.async('[js-hook-product-range-slider]', () =>
  import(/* webpackChunkName: "js/legacy/ProductRangeSlider" */ '@components/product-range-slider'),
);
moduleInit.async('[js-hook-product-tile-button]', () =>
  import(/* webpackChunkName: "js/legacy/ProductTileButton" */ '@components/product-tile-button'),
);
moduleInit.async('[js-hook-quantity-selector]', () =>
  import(/* webpackChunkName: "js/legacy/QuantitySelector" */ '@components/quantity-selector'),
);
moduleInit.async('[js-hook-remove-coupon]', () =>
  import(/* webpackChunkName: "js/legacy/RemoveCoupon" */ '@components/remove-coupon'),
);
moduleInit.async('[js-hook-promotion-banner]', () =>
  import(/* webpackChunkName: "js/legacy/PromotionBanner" */ '@components/promotion-banner'),
);
moduleInit.async('[js-hook-shipping-form]', () =>
  import(/* webpackChunkName: "js/legacy/ShippingForm" */ '@components/shipping-form'),
);

moduleInit.async('[js-hook-form-fields-toggle]', () =>
  import(/* webpackChunkName: "js/legacy/FormFieldsToggle" */ '@components/form-fields-toggle'),
);

// #region ACCOUNT-SCRIPTS
moduleInit.async('[js-hook-address-form]', () =>
  import(/* webpackChunkName: "js/legacy/AddressForm" */ '@components/address-form'),
);
moduleInit.async('[js-hook-edit-email-form]', () =>
  import(/* webpackChunkName: "js/legacy/EditEmailForm" */ '@components/edit-email-form'),
);
moduleInit.async('[js-hook-edit-password-form]', () =>
  import(/* webpackChunkName: "js/legacy/EditPasswordForm" */ '@components/edit-password-form'),
);
moduleInit.async('[js-hook-edit-profile-form]', () =>
  import(/* webpackChunkName: "js/legacy/EditProfileForm" */ '@components/edit-profile-form'),
);
moduleInit.async('[js-hook-change-to-company-form]', () =>
  import(/* webpackChunkName: "js/legacy/ChangeToCompanyForm" */ '@components/change-to-company-form'),
);
moduleInit.async('[js-hook-new-password-form]', () =>
  import(/* webpackChunkName: "js/legacy/NewPasswordForm" */ '@components/new-password-form'),
);
moduleInit.async('[js-hook-notification-form]', () =>
  import(/* webpackChunkName: "js/legacy/NotificationForm" */ '@components/notification-form'),
);
moduleInit.async('[js-hook-order-history-refinement]', () =>
  import(/* webpackChunkName: "js/legacy/OrderHistoryRefinement" */ '@components/order-history-refinement'),
);
moduleInit.async('[js-hook-register-form]', () =>
  import(/* webpackChunkName: "js/legacy/RegisterForm" */ '@components/register-form'),
);
moduleInit.async('[js-hook-create-account-form]', () =>
  import(/* webpackChunkName: "js/legacy/CreateAccountForm" */ '@components/create-account-form'),
);
moduleInit.async('[js-hook-remove-address]', () =>
  import(/* webpackChunkName: "js/legacy/RemoveAddress" */ '@components/remove-address'),
);
moduleInit.async('[js-hook-signin-form]', () =>
  import(/* webpackChunkName: "js/legacy/SigninForm" */ '@components/signin-form'),
);
moduleInit.async('[js-hook-reset-password-form]', () =>
  import(/* webpackChunkName: "js/legacy/ResetPasswordForm" */ '@components/reset-password-form'),
);
moduleInit.async('[js-hook-input-counter]', () =>
  import(/* webpackChunkName: "js/legacy/ResetPasswordForm" */ '@components/input-counter'),
);
// #endregion ACCOUNT-SCRIPTS

moduleInit.async('[js-hook-custom-select-box]', () =>
  import(/* webpackChunkName: "js/legacy/CustomSelectBox" */ '@components/custom-select-box'),
);
moduleInit.async('[js-hook-high-res-image-gallery]', () =>
  import(/* webpackChunkName: "js/legacy/HighResImageGallery" */ '@components/high-res-image-gallery'),
);
moduleInit.async('[js-hook-image-zoom]', () =>
  import(/* webpackChunkName: "js/legacy/ImageZoom" */ '@components/image-zoom'),
);
moduleInit.async('[js-hook-image-modal]', () =>
  import(/* webpackChunkName: "js/legacy/ImageModal" */ '@components/image-modal'),
);
moduleInit.async('[js-hook-countdown]', () =>
  import(/* webpackChunkName: "js/legacy/Countdown" */ '@components/countdown'),
);
moduleInit.async('[js-hook-campaign-page-block]', () =>
  import(/* webpackChunkName: "js/legacy/CampaignPageBlock" */ '@components/campaign-page-block'),
);
moduleInit.async('[js-hook-shipping-methods]', () =>
  import(/* webpackChunkName: "js/legacy/ConditionalShippingMethods" */ '@components/conditional-shipping-methods'),
);
moduleInit.async('[js-hook-product-sample-modal-toggle]', () =>
  import(/* webpackChunkName: "js/legacy/ProductSampleModalToggle" */ '@components/product-sample-modal-toggle'),
);
moduleInit.async('[js-hook-product-sample-form]', () =>
  import(/* webpackChunkName: "js/legacy/ProductSampleForm" */ '@components/product-sample-form'),
);
moduleInit.async('[js-hook-shipping-methods]', () =>
  import(/* webpackChunkName: "js/legacy/ConditionalShippingMethods" */ '@components/conditional-shipping-methods'),
);
moduleInit.async('[js-hook-download-base64-pdf]', () =>
  import(/* webpackChunkName: "js/legacy/DownloadBase64Pdf" */ '@components/download-base64-pdf'),
);
moduleInit.async('[js-hook-site-popup]', () =>
  import(/* webpackChunkName: "js/legacy/SitePopup" */ '@components/site-popup'),
);
moduleInit.async('[js-hook-textarea-add-counter]', () =>
  import(/* webpackChunkName: "js/legacy/TextareaCharCount" */ '@components/textarea-char-count'),
);
moduleInit.async('[js-hook-company-div]', () =>
import(/* webpackChunkName: "js/legacy/ChangeCompany" */ '@components/change-company'),
);
moduleInit.async('[js-hook-privacy]', () =>
import(/* webpackChunkName: "js/legacy/DisabledElement" */ '@components/disabled-element'),
);
moduleInit.async('[js-hook-swatch]', () =>
  import(/* webpackChunkName: "js/legacy/Swatch" */ '@components/swatch'),
);
moduleInit.async('[js-hook-lead-form]', () =>
  import(/* webpackChunkName: "js/legacy/LeadForm" */ '@components/lead-form'),
);

VideoLoader.then(([Platforms, Video]) => {
  Video.default.registerPlatforms({
    native: Platforms.Native,
    youtube: Platforms.Youtube,
    vimeo: Platforms.Vimeo,
  });

  Events.$trigger('video::update');
}).catch(() => {});

/**
 * We use Einstein for product recommendations. This is loaded through async JS on the template.
 * Sometimes Einstein isn't fast enough so we have to init components afterwards.
 */
document.addEventListener(
  'einstein-ready',
  () => {
    // ADD ASYNC MODULES INITS HERE WHEN EINSTEIN IS READY
    moduleInit.async('[js-hook-basic-slider]', () =>
      import(/* webpackChunkName: "js/legacy/BasicSlider" */ '@components/basic-slider'),
    );
    moduleInit.async('[js-hook-fade-slider]', () =>
      import(/* webpackChunkName: "js/legacy/FadeSlider" */ '@components/fade-slider'),
    );

    Events.$trigger('events::dom-reinit');
  },
  {
    once: true,
  },
);

Events.$on('listing::new-page', () => {
  setTimeout(() => {
    moduleInit.async('[js-hook-fade-slider]', () =>
      import(/* webpackChunkName: "js/legacy/FadeSlider" */ '@components/fade-slider'),
    );
  }, 200);
});

// load additional needed chunks
loadChunks();

// DH-4618 - Anchor link
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has("achid")) {
  const achidValue = urlParams.get("achid");
  const elementToScroll = document.getElementById(achidValue);
  if (elementToScroll) {
    Events.$trigger('scroll-to::scroll', {
      data: {
        target: elementToScroll,
        scrollElement: false // Optional, only needed when scrolling inside an element
      },
    })
  }
}
